import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as Icons from 'react-feather'

const liveDates = [
  {
    date: "Friday 3rd Feb: 1.30pm - 2.45pm",
    url: "https://bookwhen.com/squirrellearning/e/ev-sbjl-20230203133000"
  },
  {
    date: "Tuesday 7th Mar: 1.30pm - 2.45pm",
    url: "https://bookwhen.com/squirrellearning/e/ev-sz9q-20230307133000"
  },
  {
    date: "Monday 20th Mar: 9.30am - 10.45am",
    url: "https://bookwhen.com/squirrellearning/e/ev-sm3i-20230320093000"
  }
]

const WriteToRaise = () => (
  <Layout>

    <Seo title="Write To Raise" />

    <div className="wtrHero">
      <div className="container wtrHero_layout">
        <div>
          <StaticImage src="../images/wtr/brian-shapes.png" alt="Brian Blessed" width={100} />
        </div>
        <h1>Write To Raise</h1>
      </div>
    </div>

    <div className="container wtrBody">

      <div>
       <h2 className="h-small mb-1 txt-center">You will be able to start your books from 30th January 2023.</h2>
        <div className="wtrMain">

          <div className="wtrLive">
            <h2 className="h-small mb-1">Join a Free Live Event</h2>
            <p>Join a free live session led by our in-house educators. The session will focus on understanding the story-start, idea generation and story planning. The session is an ideal way to motivate your class and gear them up to write their very best story.</p>
            <div className="wtrLive_links">
              { liveDates.map(e => (
                <a href={e.url} className="wtrEventLink" target="_blank" rel="noreferrer">
                  {e.date}
                  <span className="wtrEventLink_book">
                    <span>Book your free place</span>
                    <Icons.ArrowRightCircle />
                  </span>
                </a>
              ))}
            </div>
          </div>

          <div className="wtrLive">
            <h2 className="h-small mb-1">Guides & resources</h2>
            <p>Please use the following resources and book a free training webinar to learn how to get the best out of BoomWriter.</p>
            <div className="wtrLive_links">
              <a href="/guides/wtr/book-planning-guide.pdf" className="wtrGuideLink txt-large" target="_blank">
                <Icons.Download />
                Book Planning Guide
              </a>
              <a href="/guides/wtr/teacher-adaptable-lesson-slides.pptx" className="wtrGuideLink txt-large" target="_blank">
                <Icons.Download />
                Teacher Adaptable Lesson Slides
              </a>
              <a href="/guides/gowrite/get-started-anthology.pdf" className="wtrGuideLink txt-large" target="_blank">
                <Icons.Download />
                Getting Started Guide
              </a>
              <a href="https://bookwhen.com/squirrellearning" className="wtrGuideLink txt-large" target="_blank">
                <Icons.ExternalLink />
                Book A Free Training Webinar
              </a>
            </div>
          </div>

        </div>
      </div>
      <div>
        <div className="wtrBook">
          <div className="wtrBook_img">
            <StaticImage src="../images/wtr/book-cover-3d-lr.png" alt="Book Cover" width={300} />
          </div>
          <div className="wtrBook_content">
            <h3 className="wtrBook_title">The Adventures of Brian Benedick</h3>
            <p>Started by Brian Blessed... completed by your class!</p>
          </div>
        </div>
      </div>


    </div>

  </Layout>
)

export default WriteToRaise